import { useContext, useEffect, useRef } from 'react';
import { Box, Card, CardMedia } from '@mui/material';
import { languageContext } from './context';
import trimCanvas from 'trim-canvas'

function SiteTopBar(props) {
    const host = 'https://shikkhanobish.com/';
    const [language, setLanguage] = useContext(languageContext);
    const canvasRef = useRef(null);
    useEffect(() => {
        const canvas = document.createElement('canvas');
        canvas.width=3000;
        canvas.height=1000
        const context = canvas.getContext("2d");
        context.font = "bold 42pt Hind Siliguri";
        let nameWidth = context.measureText(language.language === 'en' ? props.basicinfo.en.instituteName : props.basicinfo.bn.instituteName).width; 
        context.fillText(language.language === 'en' ? props.basicinfo.en.instituteName : props.basicinfo.bn.instituteName,(canvas.width/2) - (nameWidth / 2),60);
        context.font = "bold 16pt Hind Siliguri";
        let titleWidth = context.measureText(language.language === 'en' ? props.basicinfo.en.instituteTitle : props.basicinfo.bn.instituteTitle).width; 
        context.fillText(language.language === 'en' ? props.basicinfo.en.instituteTitle : props.basicinfo.bn.instituteTitle,(canvas.width/2) - (titleWidth / 2),96);
        context.font = "bold 16pt Hind Siliguri";
        let subTitleWidth = context.measureText(language.language === 'en' ? props.basicinfo.en.instituteSubtitle : props.basicinfo.bn.instituteSubtitle).width; 
        context.fillText(language.language === 'en' ? props.basicinfo.en.instituteSubtitle : props.basicinfo.bn.instituteSubtitle,(canvas.width/2) - (subTitleWidth / 2),125);
        canvasRef.current.style.background = "url('"+trimCanvas(canvas).toDataURL() +"') no-repeat center";
        canvasRef.current.style.backgroundSize = 'contain';
      }, [language]);
    return(
        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'column', md: 'row', lg: 'row', xl: 'row' }, alignItems: 'center' }}>
            <Box>
                <Card sx={{ maxWidth: 260, padding: '15px', textAlign: 'center', boxShadow: '0' }}>
                    <CardMedia sx={{ height: 160, width: 160 }} image={host + props.logo}/>
                </Card>
            </Box>
            <Box ref={canvasRef} sx={{ maxWidth: '100%', minHeight: '130px', flex: 1, }}>
                <Box sx={{ width: {xs: '100vw', sm: '100vw', md: 0, lg: 0, xl: 0 }, }}></Box>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }}}>
                <Card sx={{ maxWidth: 260, padding: '5px', textAlign: 'center', boxShadow: '0' }}>
                    <CardMedia sx={{ height: 134, width: 180 }} image="https://i.ibb.co/QmV2zNd/mujib-100-logo.jpg" />
                </Card>
            </Box>
        </Box>
    )
}



export default SiteTopBar;