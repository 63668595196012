import { Box, Button, Dialog, DialogContent, DialogTitle, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FixedCropper, ImageRestriction, } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import Draggable from 'react-draggable';
import '../../src/App.css';
import { useRef } from 'react';

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}
export default function ImageEditor(props) {
    const cropperRef = useRef(null);

    const onCropped = () => {
        const cropper = cropperRef.current;
        if (cropper) {
            const canvas = cropper.getCanvas();
            props.setCroppedImage(canvas.toDataURL());
        }
        props.closeEditor();
    }
    return(
        <Dialog open={props.open} PaperComponent={PaperComponent} aria-labelledby="draggable-dialog-title">
            <DialogTitle style={{ cursor: 'grab' }} id="draggable-dialog-title">
                <Box sx={{ display: 'flex', flexDirection: 'row', }}>
                    <Typography variant="title2">Resize Image</Typography>
                    <Box sx={{ flex: 1 }}></Box>
                    <Button disableElevation color="warning" onClick={props.closeEditor}>Cancel</Button>
                    <Button variant="contained" disableElevation onClick={onCropped}>Done</Button>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ maxWidth: 400, maxHeight: 400 }}>
                    <FixedCropper ref={cropperRef} src={props.image} className={'cropper'} stencilProps={{ handlers: false, lines: false, movable: false, resizable: false, }} stencilSize={{ width: 300, height: 300 }} imageRestriction={ImageRestriction.stencil} />
                </Box>
            </DialogContent>
        </Dialog>
    );
}