import { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { languageContext } from './context';
import axios from 'axios';
import { Autocomplete, Button, Card, CardMedia, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { useFilePicker } from 'use-file-picker';
import InputMask from "react-input-mask";
import ImageEditor from './imageEditor';
import moment from 'moment/moment';
import _ from 'lodash';

function AdmissionApply(props) {
    const districts =  [
    "DHAKA",
    "FARIDPUR",
    "GAZIPUR",
    "GOPALGANJ",
    "JAMALPUR",
    "KISHOREGANJ",
    "MADARIPUR",
    "MANIKGANJ",
    "MUNSHIGANJ",
    "MYMENSINGH",
    "NARAYANGANJ",
    "NARSINGDI",
    "NETROKONA",
    "RAJBARI",
    "SHARIATPUR",
    "SHERPUR",
    "TANGAIL",
    "BOGURA",
    "JOYPURHAT",
    "NAOGAON",
    "NATORE",
    "NAWABGANJ",
    "PABNA",
    "RAJSHAHI",
    "SIRAJGONJ",
    "DINAJPUR",
    "GAIBANDHA",
    "KURIGRAM",
    "LALMONIRHAT",
    "NILPHAMARI",
    "PANCHAGARH",
    "RANGPUR",
    "THAKURGAON",
    "BARGUNA",
    "BARISHAL",
    "BHOLA",
    "JHALOKATI",
    "PATUAKHALI",
    "PIROJPUR",
    "BANDARBAN",
    "BRAHMANBARIA",
    "CHANDPUR",
    "CHATTOGRAM",
    "CUMILLA",
    "COX'S BAZAR",
    "FENI",
    "KHAGRACHARI",
    "LAKSHMIPUR",
    "NOAKHALI",
    "RANGAMATI",
    "HABIGANJ",
    "MAULVIBAZAR",
    "SUNAMGANJ",
    "SYLHET",
    "BAGERHAT",
    "CHUADANGA",
    "JASHORE",
    "JHENAIDAH",
    "KHULNA",
    "KUSHTIA",
    "MAGURA",
    "MEHERPUR",
    "NARAIL",
    "SATKHIRA"
    ];
    const upazilas = [
    "AMTALI",
    "BAMNA",
    "BARGUNA SADAR",
    "BETAGI",
    "PATHARGHATA",
    "TALTALI",
    "MULADI",
    "BABUGANJ",
    "AGAILJHARA",
    "BARISAL SADAR",
    "BAKERGANJ",
    "BANARIPARA",
    "GAURNADI",
    "HIZLA",
    "MEHENDIGANJ",
    "WAZIRPUR",
    "BHOLA SADAR",
    "BURHANUDDIN",
    "CHAR FASSON",
    "DAULATKHAN",
    "LALMOHAN",
    "MANPURA",
    "TAZUMUDDIN",
    "JHALOKATI SADAR",
    "KATHALIA",
    "NALCHITY",
    "RAJAPUR",
    "BAUPHAL",
    "DASHMINA",
    "GALACHIPA",
    "KALAPARA",
    "MIRZAGANJ",
    "PATUAKHALI SADAR",
    "DUMKI",
    "RANGABALI",
    "BHANDARIA",
    "KAUKHALI",
    "MATHBARIA",
    "NAZIRPUR",
    "NESARABAD",
    "PIROJPUR SADAR",
    "ZIANAGAR",
    "BANDARBAN SADAR",
    "THANCHI",
    "LAMA",
    "NAIKHONGCHHARI",
    "ALI KADAM",
    "ROWANGCHHARI",
    "RUMA",
    "BRAHMANBARIA SADAR",
    "ASHUGANJ",
    "NASIRNAGAR",
    "NABINAGAR",
    "SARAIL",
    "SHAHBAZPUR TOWN",
    "KASBA",
    "AKHAURA",
    "BANCHARAMPUR",
    "BIJOYNAGAR",
    "CHANDPUR SADAR",
    "FARIDGANJ",
    "HAIMCHAR",
    "HAZIGANJ",
    "KACHUA",
    "MATLAB UTTAR",
    "MATLAB DAKKHIN",
    "SHAHRASTI",
    "ANWARA",
    "BANSHKHALI",
    "BOALKHALI",
    "CHANDANAISH",
    "FATIKCHHARI",
    "HATHAZARI",
    "LOHAGARA",
    "MIRSHARAI",
    "PATIYA",
    "RANGUNIA",
    "RAOZAN",
    "SANDWIP",
    "SATKANIA",
    "SITAKUNDA",
    "BARURA",
    "BRAHMANPARA",
    "BURICHONG",
    "CHANDINA",
    "CHAUDDAGRAM",
    "DAUDKANDI",
    "DEBIDWAR",
    "HOMNA",
    "COMILLA SADAR",
    "LAKSAM",
    "MONOHORGONJ",
    "MEGHNA",
    "MURADNAGAR",
    "NANGALKOT",
    "COMILLA SADAR SOUTH",
    "TITAS",
    "CHAKARIA",
    "{{198}}''{{199}}",
    "KUTUBDIA",
    "MAHESHKHALI",
    "RAMU",
    "TEKNAF",
    "UKHIA",
    "PEKUA",
    "FENI SADAR",
    "CHAGALNAIYA",
    "DAGANBHYAN",
    "PARSHURAM",
    "FHULGAZI",
    "SONAGAZI",
    "DIGHINALA",
    "KHAGRACHHARI",
    "LAKSHMICHHARI",
    "MAHALCHHARI",
    "MANIKCHHARI",
    "MATIRANGA",
    "PANCHHARI",
    "RAMGARH",
    "LAKSHMIPUR SADAR",
    "RAIPUR",
    "RAMGANJ",
    "RAMGATI",
    "KOMOL NAGAR",
    "NOAKHALI SADAR",
    "BEGUMGANJ",
    "CHATKHIL",
    "COMPANYGANJ",
    "SHENBAG",
    "HATIA",
    "KOBIRHAT",
    "SONAIMURI",
    "SUBORNO CHAR",
    "RANGAMATI SADAR",
    "BELAICHHARI",
    "BAGAICHHARI",
    "BARKAL",
    "JURAICHHARI",
    "RAJASTHALI",
    "KAPTAI",
    "LANGADU",
    "NANNERCHAR",
    "KAUKHALI",
    "DHAMRAI",
    "DOHAR",
    "KERANIGANJ",
    "NAWABGANJ",
    "SAVAR",
    "FARIDPUR SADAR",
    "BOALMARI",
    "ALFADANGA",
    "MADHUKHALI",
    "BHANGA",
    "NAGARKANDA",
    "CHARBHADRASAN",
    "SADARPUR",
    "SHALTHA",
    "GAZIPUR SADAR-JOYDEBPUR",
    "KALIAKIOR",
    "KAPASIA",
    "SRIPUR",
    "KALIGANJ",
    "TONGI",
    "GOPALGANJ SADAR",
    "KASHIANI",
    "KOTALIPARA",
    "MUKSUDPUR",
    "TUNGIPARA",
    "DEWANGANJ",
    "BAKSIGANJ",
    "ISLAMPUR",
    "JAMALPUR SADAR",
    "MADARGANJ",
    "MELANDAHA",
    "SARISHABARI",
    "NARUNDI POLICE I.C",
    "ASTAGRAM",
    "BAJITPUR",
    "BHAIRAB",
    "HOSSAINPUR",
    "ITNA",
    "KARIMGANJ",
    "KATIADI",
    "KISHOREGANJ SADAR",
    "KULIARCHAR",
    "MITHAMAIN",
    "NIKLI",
    "PAKUNDIA",
    "TARAIL",
    "MADARIPUR SADAR",
    "KALKINI",
    "RAJOIR",
    "SHIBCHAR",
    "MANIKGANJ SADAR",
    "SINGAIR",
    "SHIBALAYA",
    "SATURIA",
    "HARIRAMPUR",
    "GHIOR",
    "DAULATPUR",
    "LOHAJANG",
    "SREENAGAR",
    "MUNSHIGANJ SADAR",
    "SIRAJDIKHAN",
    "TONGIBARI",
    "GAZARIA",
    "BHALUKA",
    "TRISHAL",
    "HALUAGHAT",
    "MUKTAGACHHA",
    "DHOBAURA",
    "FULBARIA",
    "GAFFARGAON",
    "GAURIPUR",
    "ISHWARGANJ",
    "MYMENSINGH SADAR",
    "NANDAIL",
    "PHULPUR",
    "ARAIHAZAR",
    "SONARGAON",
    "BANDAR",
    "NARYANGANJ SADAR",
    "RUPGANJ",
    "SIDDIRGONJ",
    "BELABO",
    "MONOHARDI",
    "NARSINGDI SADAR",
    "PALASH",
    "RAIPURA, NARSINGDI",
    "SHIBPUR",
    "KENDUA UPAZILLA",
    "ATPARA UPAZILLA",
    "BARHATTA UPAZILLA",
    "DURGAPUR UPAZILLA",
    "KALMAKANDA UPAZILLA",
    "MADAN UPAZILLA",
    "MOHANGANJ UPAZILLA",
    "NETRAKONA-S UPAZILLA",
    "PURBADHALA UPAZILLA",
    "KHALIAJURI UPAZILLA",
    "BALIAKANDI",
    "GOALANDAGHAT",
    "PANGSHA",
    "KALUKHALI",
    "RAJBARI SADAR",
    "SHARIATPUR SADAR -PALONG",
    "DAMUDYA",
    "NARIA",
    "JAJIRA",
    "BHEDARGANJ",
    "GOSAIRHAT",
    "JHENAIGATI",
    "NAKLA",
    "NALITABARI",
    "SHERPUR SADAR",
    "SREEBARDI",
    "TANGAIL SADAR",
    "SAKHIPUR",
    "BASAIL",
    "MADHUPUR",
    "GHATAIL",
    "KALIHATI",
    "NAGARPUR",
    "MIRZAPUR",
    "GOPALPUR",
    "DELDUAR",
    "BHUAPUR",
    "DHANBARI",
    "BAGERHAT SADAR",
    "CHITALMARI",
    "FAKIRHAT",
    "KACHUA",
    "MOLLAHAT",
    "MONGLA",
    "MORRELGANJ",
    "RAMPAL",
    "SARANKHOLA",
    "DAMURHUDA",
    "CHUADANGA-S",
    "JIBANNAGAR",
    "ALAMDANGA",
    "ABHAYNAGAR",
    "KESHABPUR",
    "BAGHERPARA",
    "JESSORE SADAR",
    "CHAUGACHHA",
    "MANIRAMPUR",
    "JHIKARGACHHA",
    "SHARSHA",
    "JHENAIDAH SADAR",
    "MAHESHPUR",
    "KALIGANJ",
    "KOTCHANDPUR",
    "SHAILKUPA",
    "HARINAKUNDA",
    "TEROKHADA",
    "BATIAGHATA",
    "DACOPE",
    "DUMURIA",
    "DIGHALIA",
    "KOYRA",
    "PAIKGACHHA",
    "PHULTALA",
    "RUPSA",
    "KUSHTIA SADAR",
    "KUMARKHALI",
    "DAULATPUR",
    "MIRPUR",
    "BHERAMARA",
    "KHOKSA",
    "MAGURA SADAR",
    "MOHAMMADPUR",
    "SHALIKHA",
    "SREEPUR",
    "ANGNI",
    "MUJIB NAGAR",
    "MEHERPUR-S",
    "NARAIL-S UPAZILLA",
    "LOHAGARA UPAZILLA",
    "KALIA UPAZILLA",
    "SATKHIRA SADAR",
    "ASSASUNI",
    "DEBHATA",
    "TALA",
    "KALAROA",
    "KALIGANJ",
    "SHYAMNAGAR",
    "ADAMDIGHI",
    "BOGRA SADAR",
    "SHERPUR",
    "DHUNAT",
    "DHUPCHANCHIA",
    "GABTALI",
    "KAHALOO",
    "NANDIGRAM",
    "SAHAJANPUR",
    "SARIAKANDI",
    "SHIBGANJ",
    "SONATALA",
    "JOYPURHAT S",
    "AKKELPUR",
    "KALAI",
    "KHETLAL",
    "PANCHBIBI",
    "NAOGAON SADAR",
    "MOHADEVPUR",
    "MANDA",
    "NIAMATPUR",
    "ATRAI",
    "RANINAGAR",
    "PATNITALA",
    "DHAMOIRHAT",
    "SAPAHAR",
    "PORSHA",
    "BADALGACHHI",
    "NATORE SADAR",
    "BARAIGRAM",
    "BAGATIPARA",
    "LALPUR",
    "NATORE SADAR",
    "BARAIGRAM",
    "BHOLAHAT",
    "GOMASTAPUR",
    "NACHOLE",
    "NAWABGANJ SADAR",
    "SHIBGANJ",
    "ATGHARIA",
    "BERA",
    "BHANGURA",
    "CHATMOHAR",
    "FARIDPUR",
    "ISHWARDI",
    "PABNA SADAR",
    "SANTHIA",
    "SUJANAGAR",
    "BAGHA",
    "BAGMARA",
    "CHARGHAT",
    "DURGAPUR",
    "GODAGARI",
    "MOHANPUR",
    "PABA",
    "PUTHIA",
    "TANORE",
    "SIRAJGANJ SADAR",
    "BELKUCHI",
    "CHAUHALI",
    "KAMARKHANDA",
    "KAZIPUR",
    "RAIGANJ",
    "SHAHJADPUR",
    "TARASH",
    "ULLAHPARA",
    "BIRAMPUR",
    "BIRGANJ",
    "BIRAL",
    "BOCHAGANJ",
    "CHIRIRBANDAR",
    "PHULBARI",
    "GHORAGHAT",
    "HAKIMPUR",
    "KAHAROLE",
    "KHANSAMA",
    "DINAJPUR SADAR",
    "NAWABGANJ",
    "PARBATIPUR",
    "FULCHHARI",
    "GAIBANDHA SADAR",
    "GOBINDAGANJ",
    "PALASHBARI",
    "SADULLAPUR",
    "SAGHATA",
    "SUNDARGANJ",
    "KURIGRAM SADAR",
    "NAGESHWARI",
    "BHURUNGAMARI",
    "PHULBARI",
    "RAJARHAT",
    "ULIPUR",
    "CHILMARI",
    "ROWMARI",
    "CHAR RAJIBPUR",
    "LALMANIRHAT SADAR",
    "ADITMARI",
    "KALIGANJ",
    "HATIBANDHA",
    "PATGRAM",
    "NILPHAMARI SADAR",
    "SAIDPUR",
    "JALDHAKA",
    "KISHOREGANJ",
    "DOMAR",
    "DIMLA",
    "PANCHAGARH SADAR",
    "DEBIGANJ",
    "BODA",
    "ATWARI",
    "TETULIA",
    "BADARGANJ",
    "MITHAPUKUR",
    "GANGACHARA",
    "KAUNIA",
    "RANGPUR SADAR",
    "PIRGACHHA",
    "PIRGANJ",
    "TARAGANJ",
    "THAKURGAON SADAR",
    "PIRGANJ",
    "BALIADANGI",
    "HARIPUR",
    "RANISANKAIL",
    "AJMIRIGANJ",
    "BANIACHANG",
    "BAHUBAL",
    "CHUNARUGHAT",
    "HABIGANJ SADAR",
    "LAKHAI",
    "MADHABPUR",
    "NABIGANJ",
    "SHAISTAGONJ",
    "MOULVIBAZAR SADAR",
    "BARLEKHA",
    "JURI",
    "KAMALGANJ",
    "KULAURA",
    "RAJNAGAR",
    "SREEMANGAL",
    "BISHWAMVARPUR",
    "CHHATAK",
    "DERAI",
    "DHARAMPASHA",
    "DOWARABAZAR",
    "JAGANNATHPUR",
    "JAMALGANJ",
    "SULLA",
    "SUNAMGANJ SADAR",
    "SHANTHIGANJ",
    "TAHIRPUR",
    "SYLHET SADAR",
    "BEANIBAZAR",
    "BISHWANATH",
    "DAKSHIN SURMA",
    "BALAGANJ",
    "COMPANIGANJ",
    "FENCHUGANJ",
    "GOLAPGANJ",
    "GOWAINGHAT",
    "JOINTAPUR",
    "KANAIGHAT",
    "ZAKIGANJ",
    "NOBIGONJ",
    "EIDGAON",
    "MODHYANAGAR",
    "DASAR"
    ];
    const [language] = useContext(languageContext);
    const [admission, setAdmission] = useState(null);
    const [applicantData, setApplicantData] = useState({
      admissionClass: "",
      admissionSection: "",
      admissionYear: "",
      applicantNameInEnglish: "",
      applicantNameInBangla: "",
      applicantDateOfBirth: "",
      birthCertificateNo: "",
      gender: "",
      religion: "",
      formerSchoolName: "",
      freedomFightersChild: "",
      fatherNameInEnglish: "",
      fatherNameInBangla: "",
      fatherOccupation: "",
      fatherNid: "",
      fatherPhoneNo: "",
      motherNameInEnglish: "",
      motherNameInBangla: "",
      motherOccupation: "",
      motherNid: "",
      motherPhoneNo: "",
      presentVillage: "",
      presentPostOffice: "",
      presentUpazila: "",
      presentDistrict: "",
      permanentVillage: "",
      permanentPostOffice: "",
      permanentUpazila: "",
      permanentDistrict: "",
      gurdianName: "",
      gurdianPhoneNo: "",
      gurdianYearlyIncome: "",
      avatar: "",
      applicationDate: "",
      lastSubmissionDate: ""
    });
    const [editor, setEditor] = useState(false);
    const [picture, setPicture] = useState('https://shikkhanobish.com/files/default_avatar.png');
    const { openFilePicker, filesContent, loading, errors, plainFiles, clear } = useFilePicker({
      multiple: false,
      readAs: 'DataURL',
      accept: ['.png', '.jpg', '.jpeg'],
      onFilesSelected: ({ plainFiles, filesContent, errors }) => {
        // this callback is always called, even if there are errors
        console.log('onFilesSelected', plainFiles, filesContent, errors);
        setEditor(true);
      },
      onFilesRejected: ({ errors }) => {
        // this callback is called when there were validation errors
        console.log('onFilesRejected', errors);
      },
      onFilesSuccessfullySelected: ({ plainFiles, filesContent }) => {
        // this callback is called when there were no validation errors
        console.log('onFilesSuccessfullySelected', plainFiles, filesContent);
      },
      onClear: () => {
        // this callback is called when the selection is cleared
        console.log('onClear');
      },
    });

    const previewApplication = async()  => {
      console.log(applicantData);
      var newWindow;
      if(picture == 'https://shikkhanobish.com/files/default_avatar.png') {
        alert('Please upload your image first.')
      } else {
        const anyEmpty = _.some(_.omit(applicantData, ['avatar', 'applicationDate', 'lastSubmissionDate', "presentVillage","presentPostOffice","presentUpazila","presentDistrict","permanentVillage","permanentPostOffice","permanentUpazila","permanentDistrict"]), _.isEmpty);
        if(anyEmpty) {
          alert('Please fill up all fileds.')
        } else {
          if (newWindow && !newWindow.closed) {
              newWindow.focus();
          } else {
              newWindow = window.open('', '_blank', 'width=595,height=842,resizable=no,menubar=no');
              newWindow.document.write('<link rel="preconnect" href="https://fonts.googleapis.com">');
              newWindow.document.write('<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>');
              newWindow.document.write('<link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed&family=Tiro+Bangla&display=swap" rel="stylesheet">');
              newWindow.document.write("<style>table, td, th {  border: 1px solid #ddd; font-family: 'Roboto Condensed', sans-serif, 'Tiro Bangla', serif; ; text-align: left;}table {border-collapse: collapse;width: 100%;} td {padding: 6px;}</style>")
              newWindow.document.write("<table>");
              _.forOwn(_.omit(applicantData, ['avatar', 'applicationDate', 'lastSubmissionDate', "presentVillage","presentPostOffice","presentUpazila","presentDistrict","permanentVillage","permanentPostOffice","permanentUpazila","permanentDistrict"]), function(value, key) {
                newWindow.document.write("<tr><td>" + _.startCase(key) + "</td><td>" + value + "</td></tr>");
              });
              newWindow.document.write("<tr><td>Present Address</td><td>" + applicantData.presentVillage, ", " + applicantData.presentDistrict + ", " + applicantData.presentUpazila + ", " + applicantData.presentPostOffice + ", " + "</td></tr>");
              newWindow.document.write("<tr><td>Permanent Address</td><td>" + applicantData.permanentVillage + ", " + applicantData.permanentDistrict + ", " + applicantData.permanentUpazila + ", " + applicantData.permanentPostOffice + "</td></tr>");
              newWindow.document.write("</table>");
          }
        }
      }
    }

    const submitApplication = async(data) => {
      if(picture == 'https://shikkhanobish.com/files/default_avatar.png') {
        alert('Please upload your image first.')
      } else {
        const anyEmpty = _.some(_.omit(applicantData, ['avatar', 'applicationDate', 'lastSubmissionDate', "presentVillage","presentPostOffice","presentUpazila","presentDistrict","permanentVillage","permanentPostOffice","permanentUpazila","permanentDistrict"]), _.isEmpty);
        if(anyEmpty) {
          alert('Please fill up all fileds.')
        } else {
          axios.post('https://shikkhanobish.com/storefile', {file: picture.split("base64,")[1], mimetype: picture.split(";base64,")[0]})
          .then((res) => {
            if(!res.data.error) {
              let applicationDate = moment().format('DD/MM/YYYY');
              let lastSubmissionDate = moment().add(10, 'days').format('DD/MM/YYYY');
              let avatar = res.data.media;
              let newData = {
                eiin: admission.panel.eiin,
                values:{
                  ...applicantData,
                  applicationDate: applicationDate,
                  lastSubmissionDate: lastSubmissionDate,
                  avatar: avatar
                }
              }
              postApplication(newData)
            } else {
              alert(res.data.message);
            }
          });
        }
      }
    }

    const postApplication = async(data) => {
      axios.post('https://shikkhanobish.com/submitadmissionapplication', data)
      .then(async(res)  => {
        if(res.data.error === false) {
          alert(res.data.message);
          window.location.href="https://shikkhanobish.com/api/downloadadmissionform?eiin=" + admission.panel.eiin + "&birthcertificateno=" + data.values.birthCertificateNo
        } else {
          alert(res.data.message);
        }
      }).catch((err) => {
        alert('Something went wrong! Try again.')
      });
    }

    useEffect(() => {
      axios.get('https://shikkhanobish.com/api/admissionapplication')
      .then((res) => {
        setAdmission(res.data);
      })
    }, [applicantData]);
      return (
      <Box sx={{ backgroundColor: '#01579b', }}>
        {!!filesContent.length && <ImageEditor image={filesContent[0].content} open={editor} closeEditor={() => setEditor(false)} setCroppedImage={(image) => setPicture(image) } /> }
        <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 26, fontWeight: 700, color: 'white', textAlign: 'center', padding: '25px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>{language.language === 'en' ? 'Apply for admission' : 'ভর্তি আবেদন'}</Box>
        <Box sx={{  display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'center' }}>
            {
              admission == null ?
              <Box component="p" sx={{ maxWidth : 520, padding: 2, fontFamily: 'Hind Siliguri', color: 'white', fontSize: 22, whiteSpace: 'pre-wrap'}}>
                Admission is currently closed.
              </Box>
              :
              <Box sx={{ background: 'white', display: 'flex', flexDirection: 'column', flex: 1, padding: 3, margin: 3, borderRadius: 2 }}>
                <Card sx={{ width: 140, height: 140, marginY: 1, marginX: 'auto' }} onClick={() => openFilePicker()}>
                  {!!picture.length && <CardMedia sx={{ height: 140, width: 140 }} image={picture} />}
                </Card>

                <Paper variant="outlined" sx={{ padding: 2, backgroundColor: '#f2f7ff', display: 'flex', flexDirection: 'column', flex: 1, margin: 2, borderRadius: 2 }}>
                  <Typography variant="title" sx={{ fontWeight: 'bold', mx: 1, my: 1 }}>Applicant Information</Typography>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <FormControl sx={{ m: 1, flex: 1 }} size="small">
                      <InputLabel id="admission-class">Admission Class</InputLabel>
                      <Select label="Admission Class" size="small" id="class" onChange={(event) => setApplicantData({...applicantData, admissionClass : event.target.value})}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {
                          admission.panel.admissionClasses.map((item, index) => {
                            return(
                              <MenuItem value={item} key={index}>{item}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, flex: 1 }} size="small">
                      <InputLabel id="admission-section">Admission Section</InputLabel>
                      <Select label="Admission Section" size="small" id="section" onChange={(event) => setApplicantData({...applicantData, admissionSection : event.target.value})}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {
                          admission.panel.admissionSections.map((item, index) => {
                            return(
                              <MenuItem value={item} key={index}>{item}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, flex: 1 }} size="small">
                      <InputLabel id="admission-year">Admission Year</InputLabel>
                      <Select label="Admission Year" size="small" id="year" onChange={(event) => setApplicantData({...applicantData, admissionYear : event.target.value})}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {
                          admission.panel.admissionYears.map((item, index) => {
                            return(
                              <MenuItem value={item} key={index}>{item}</MenuItem>
                            )
                          })
                        }
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[a-zA-Z.(): ]'}} value={applicantData.applicantNameInEnglish} onChange={(e) => setApplicantData({...applicantData, applicantNameInEnglish: e.target.value.toUpperCase() })}>
                      {() => <TextField label="Applicant Full Name in English" id="applicant-first-name" size="small" sx={{ flex: 1, margin: 1 }} /> }
                    </InputMask>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[\u0980-\u09FF.(): ]+'}} value={applicantData.applicantNameInBangla} onChange={(e) => setApplicantData({...applicantData, applicantNameInBangla: e.target.value })}>
                      {() => <TextField label="Applicant Full Name in Bengali" id="applicant-first-name" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                  </Box>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <InputMask mask="99/99/9999" value={applicantData.applicantDateOfBirth} onChange={(e) => setApplicantData({...applicantData, applicantDateOfBirth: e.target.value })}>
                      {() => <TextField label="Applicant Date of birth" id="applicant-dob" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                    <InputMask mask="99999999999999999999" maskChar={null} alwaysShowMask={false} value={applicantData.birthCertificateNo} onChange={(e) => setApplicantData({...applicantData, birthCertificateNo: e.target.value })}>
                      {() =><TextField label="Applicant Birth Certificate no" id="applicant-first-name" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                  </Box>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <FormControl sx={{ m: 1, flex: 1 }} size="small">
                      <InputLabel id="demo-select-small-label">Gender</InputLabel>
                      <Select label="Gender" size="small" id="gender" onChange={(event) => setApplicantData({...applicantData, gender: event.target.value})}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Male">MALE</MenuItem>
                        <MenuItem value="Female">FEMALE</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, flex: 1 }} size="small">
                      <InputLabel id="demo-select-small-label">Religion</InputLabel>
                      <Select label="Religion" size="small" id="religion" onChange={(event) => setApplicantData({...applicantData, religion: event.target.value})}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Islam">ISLAM</MenuItem>
                        <MenuItem value="Hinduism">HINDUISM</MenuItem>
                        <MenuItem value="Buddhism">BUDDHISM</MenuItem>
                        <MenuItem value="Christianity">CHRISTIANITY</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                  <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[a-zA-Z.(): .-]'}} value={applicantData.formerSchoolName} onChange={(e) => setApplicantData({...applicantData, formerSchoolName: e.target.value })} >
                    {() => <TextField label="Former school name in English" id="former school" size="small" sx={{ flex: 1, margin: 1 }} />}
                  </InputMask>
                    <FormControl sx={{ m: 1, flex: 1 }} size="small">
                      <InputLabel id="demo-select-small-label">Freedom Fighter Child</InputLabel>
                      <Select label="Freedom Fighter Child" size="small" id="gender" onChange={(event) => setApplicantData({...applicantData, freedomFightersChild: event.target.value})}>
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Paper>

                <Paper variant="outlined" sx={{ padding: 2, backgroundColor: '#f2f7ff', display: 'flex', flexDirection: 'column', flex: 1, margin: 2, borderRadius: 2 }}>
                  <Typography variant="title" sx={{ fontWeight: 'bold', mx: 1, my: 1 }}>Gurdian Information</Typography>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[a-zA-Z.(): .-]'}} value={applicantData.fatherNameInEnglish} onChange={(e) => setApplicantData({...applicantData, fatherNameInEnglish: e.target.value.toUpperCase() })} >
                      {() => <TextField label="Father's Full Name in English" id="fathernameen" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[a-zA-Z.(): .-]'}} value={applicantData.motherNameInEnglish} onChange={(e) => setApplicantData({...applicantData, motherNameInEnglish: e.target.value.toUpperCase() })} >
                      {() => <TextField label="Mother's Full Name in English" id="mothenameen" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                  </Box>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[\u0980-\u09FF.(): ]+'}} value={applicantData.fatherNameInBangla} onChange={(e) => setApplicantData({...applicantData, fatherNameInBangla: e.target.value })}>
                      {() => <TextField label="Father's Full Name in Bengali" id="fathernamebn" size="small" sx={{ flex: 1, margin: 1 }} /> }
                    </InputMask>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[\u0980-\u09FF.(): ]+'}} value={applicantData.motherNameInBangla} onChange={(e) => setApplicantData({...applicantData, motherNameInBangla: e.target.value })}>
                      {() => <TextField label="Mother's Full Name in Bengali" id="mothernamebn" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                  </Box>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[a-zA-Z.(): .-]'}} value={applicantData.fatherOccupation} onChange={(e) => setApplicantData({...applicantData, fatherOccupation: e.target.value })} >
                      {() => <TextField label="Father's Occupation" id="father-occupation" size="small" sx={{ flex: 1, margin: 1 }} /> }
                    </InputMask>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[a-zA-Z.(): .-]'}} value={applicantData.motherOccupation} onChange={(e) => setApplicantData({...applicantData, motherOccupation: e.target.value })} >
                      {() => <TextField label="Mother's Occupation" id="mother-occupation" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                  </Box>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <InputMask mask="9999999999999" maskChar={null} alwaysShowMask={false} value={applicantData.fatherNid} onChange={(e) => setApplicantData({...applicantData, fatherNid: e.target.value })}>
                        {() => <TextField label="Father's NID no" id="father-nid" size="small" sx={{ flex: 1, margin: 1 }} /> }
                    </InputMask>
                    <InputMask mask="9999999999999" maskChar={null} alwaysShowMask={false} value={applicantData.motherNid} onChange={(e) => setApplicantData({...applicantData, motherNid: e.target.value })}>
                      {() => <TextField label="Mother's NID no" id="mother-nid" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                  </Box>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <InputMask mask="01abb-bbbbbb" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[3-9]', 'b': '[0-9]'}} value={applicantData.fatherPhoneNo} onChange={(e) => setApplicantData({...applicantData, fatherPhoneNo: e.target.value })} >
                        {() => <TextField label="Father's Phone no" id="father-phone" size="small" sx={{ flex: 1, margin: 1 }} /> }
                    </InputMask>
                    
                    <InputMask mask="01abb-bbbbbb" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[3-9]', 'b': '[0-9]'}} value={applicantData.motherPhoneNo} onChange={(e) => setApplicantData({...applicantData, motherPhoneNo: e.target.value })} >
                      {() => <TextField label="Mother's Phone no" id="mother-phone" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                  </Box>
                </Paper>

                <Paper variant="outlined" sx={{ padding: 2, backgroundColor: '#f2f7ff', display: 'flex', flexDirection: 'column', flex: 1, margin: 2, borderRadius: 2 }}>
                  <Typography variant="title" sx={{ fontWeight: 'bold', mx: 1, my: 1 }}>Present Address</Typography>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[a-zA-Z.(): .-]'}} value={applicantData.presentVillage} onChange={(e) => setApplicantData({...applicantData, presentVillage: e.target.value })} >
                      {() => <TextField label="Village/Town" id="present-village" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[a-zA-Z.(): .-]'}} value={applicantData.presentPostOffice} onChange={(e) => setApplicantData({...applicantData, presentPostOffice: e.target.value })} >
                      {() => <TextField label="Post Office" id="present-post-office" size="small" sx={{ flex: 1, margin: 1 }} /> }
                    </InputMask>
                  </Box>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                  <Autocomplete disablePortal id="present-upazila" size="small" options={upazilas} sx={{ width: 300, flex: 1, margin: 1 }} renderInput={(params) => <TextField size="small" {...params} label="Upazila" />} value={applicantData.presentUpazila} onInputChange={(e, v) => setApplicantData({...applicantData, presentUpazila: _.isEmpty(v) ? '' : v.toUpperCase() })} onChange={(e, v) => setApplicantData({...applicantData, presentUpazila: _.isEmpty(v) ? '' : v.toUpperCase() })}/>
                  <Autocomplete disablePortal id="present-discrict" size="small" options={districts} sx={{ width: 300, flex: 1, margin: 1 }} renderInput={(params) => <TextField size="small" {...params} label="District" />} value={applicantData.presentDistrict} onInputChange={(e, v) => setApplicantData({...applicantData, presentDistrict: _.isEmpty(v) ? '' : v.toUpperCase() })} onChange={(e, v) => setApplicantData({...applicantData, presentDistrict: _.isEmpty(v) ? '' : v.toUpperCase() })}/>
                  </Box>
                </Paper>

                <Paper variant="outlined" sx={{ padding: 2, backgroundColor: '#f2f7ff', display: 'flex', flexDirection: 'column', flex: 1, margin: 2, borderRadius: 2 }}>
                  <Typography variant="title" sx={{ fontWeight: 'bold', mx: 1, my: 1 }}>Permanent Address</Typography>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[a-zA-Z.(): .-]'}} value={applicantData.permanentVillage} onChange={(e) => setApplicantData({...applicantData, permanentVillage: e.target.value })} >
                      {() => <TextField label="Village/Town" id="permanent-village" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[a-zA-Z.(): .-]'}} value={applicantData.permanentPostOffice} onChange={(e) => setApplicantData({...applicantData, permanentPostOffice: e.target.value })} >
                      {() => <TextField label="Post Office" id="permanent-post-office" size="small" sx={{ flex: 1, margin: 1 }} /> }
                    </InputMask>
                  </Box>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <Autocomplete disablePortal id="permanent-upazila" size="small" options={upazilas} sx={{ width: 300, flex: 1, margin: 1 }} renderInput={(params) => <TextField size="small" {...params} label="Upazila" />} value={applicantData.permanentUpazila} onInputChange={(e, v) => setApplicantData({...applicantData, permanentUpazila: _.isEmpty(v) ? '' : v.toUpperCase() })} onChange={(e, v) => setApplicantData({...applicantData, permanentUpazila: _.isEmpty(v) ? '' : v.toUpperCase() })}/>
                    <Autocomplete disablePortal id="permanent-discrict" size="small" options={districts} sx={{ width: 300, flex: 1, margin: 1 }} renderInput={(params) => <TextField size="small" {...params} label="District" />} value={applicantData.permanentDistrict} onInputChange={(e, v) => setApplicantData({...applicantData, permanentDistrict: _.isEmpty(v) ? '' : v.toUpperCase() })} onChange={(e, v) => setApplicantData({...applicantData, permanentDistrict: _.isEmpty(v) ? '' : v.toUpperCase() })}/>
                  </Box>
                </Paper>


                <Paper variant="outlined" sx={{ padding: 2, backgroundColor: '#f2f7ff', display: 'flex', flexDirection: 'column', flex: 1, borderRadius: 2, margin: 2  }}>
                  <Typography variant="title" sx={{ fontWeight: 'bold', mx: 1, my: 1 }}>Legal Gurdian Information</Typography>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[a-zA-Z.(): .-]'}} value={applicantData.gurdianName} onChange={(e) => setApplicantData({...applicantData, gurdianName: e.target.value.toUpperCase() })} >
                      {() => <TextField label="Gurdian name" id="gurdian-name" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                    <InputMask mask="01abb-bbbbbb" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[3-9]', 'b': '[0-9]'}} value={applicantData.gurdianPhoneNo} onChange={(e) => setApplicantData({...applicantData, gurdianPhoneNo: e.target.value })} >
                      {() => <TextField label="Gurdian Phone" id="gurdian-phone" size="small" sx={{ flex: 1, margin: 1 }} /> }
                    </InputMask>
                  </Box>
                  <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                    <InputMask mask="aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa" maskChar={null} alwaysShowMask={false} formatChars={{'a': '[0-9]'}} value={applicantData.gurdianYearlyIncome} onChange={(e) => setApplicantData({...applicantData, gurdianYearlyIncome: e.target.value })} >
                      {() => <TextField label="Gurdian Yearly Income" id="gurdian-yearly-income" size="small" sx={{ flex: 1, margin: 1 }} /> }
                    </InputMask>
                  </Box>
                </Paper>
                <Box sx={{ display: 'flex', flex: 1, flexDirection: { xs: 'column', sm: 'column', md: 'row' } }}>
                  <Box component="p" sx={{ padding: 2, fontFamily: 'Hind Siliguri', color: 'black', fontSize: 16, whiteSpace: 'pre-wrap'}}>
                    {admission.panel.guideline}
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end', px: 2 }}>
                  <Button variant="contained" sx={{ mr: 2 }} color="warning" disableElevation onClick={() => previewApplication()}>Preview Application</Button>
                  <Button variant="contained" color="success" disableElevation onClick={() => submitApplication()}>Submit Application</Button>
                </Box>
              </Box>
            }
        </Box>
      </Box>
      )
  }
  
export default AdmissionApply;