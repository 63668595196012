import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Box from '@mui/material/Box';
import { Pagination, Autoplay } from "swiper";

function Carousel(props) {
    const host = 'https://shikkhanobish.com/';
    return (
        <Swiper mousewheel={true} pagination={{ dynamicBullets: true, clickable: true, }} autoplay={{ delay: 2500, disableOnInteraction: false, }} modules={[Pagination, Autoplay]} className="mySwiper">
            {
                props.slides.map((slide, i) => {
                    return(
                        <SwiperSlide key={i}>
                            <Box sx={{ aspectRatio: '2000/678', maxHeight: '100%', maxWidth: 2000, background: ' url('+ host + slide +') no-repeat center center', backgroundSize: '100% 100%', display: 'flex', justifyContent: 'center'}}>
                                <Box sx={{ background: 'rgba(0, 0, 0, 0.85)', flex: 1, display: 'flex', justifyContent: 'center' }}>
                                    <Box component="img" sx={{ maxWidth: '100%', marginX: 'auto' }} src={host+slide}></Box>
                                </Box>
                            </Box>
                        </SwiperSlide>
                    )
                })
            }
      </Swiper>
      );
}

export default Carousel;