import { useContext } from 'react';
import { Box} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { languageContext } from './context';
import Link from '@mui/material/Link';
function Weblinks(props) {
    const [language] = useContext(languageContext);
    const links = [
        {
            name: {
                en: 'Education Board',
                bn: 'শিক্ষা বোর্ড'
            },
            link: 'http://www.educationboard.gov.bd/',
            email: 'N/A',
            phone: 'N/A'
        },
        {
            name: {
                en: 'CRVS',
                bn: 'সিআরভিএস'
            },
            link: 'http://crvs-institute.banbeis.gov.bd/',
            email: 'info@banbeis.gov.bd',
            phone: '02-9665457'
        },
        {
            name: {
                en: 'EDUCATION MANAGEMENT INFORMATION SYSTEM (EMIS)',
                bn: 'ইএমআইএস'
            },
            link: 'http://emis.gov.bd/emis',
            email: 'info.emis.dshe@gmail.com',
            phone: '+8801674213214'
        },
        {
            name: {
                en: 'Shikkhok Batayon',
                bn: 'শিক্ষক বাতায়ন'
            },
            link: 'https://www.teachers.gov.bd/',
            email: 'teachersportal@a2i.gov.bd',
            phone: '+8801810037926'
        },
        {
            name: {
                en: "Non-govt Teacher's Registration & Certificate Authority",
                bn: 'বেসরকারি শিক্ষক নিবন্ধন ও সনদ কর্তৃপক্ষ'
            },
            link: 'http://ntrca.teletalk.com.bd/admitcard/index.php',
            email: 'ntrca2005@yahoo.com',
            phone: 'N/A'
        },
        {
            name: {
                en: "Directorate of Secondary and Higher Education (DSHE)",
                bn: 'মাধ্যমিক ও উচ্চ শিক্ষা অধিদপ্তর'
            },
            link: 'http://www.dshe.gov.bd/',
            email: 'N/A',
            phone: 'N/A'
        },
        {
            name: {
                en: "Ministry of Education",
                bn: 'শিক্ষা মন্ত্রণালয়'
            },
            link: 'https://moedu.gov.bd/',
            email: 'info@moedu.gov.bd',
            phone: '9576679'
        },
        {
            name: {
                en: "Directorate of Primary Education",
                bn: 'প্রাথমিক শিক্ষা অধিদপ্তর'
            },
            link: 'http://www.dpe.gov.bd/',
            email: 'ddestabdpe@gmail.com',
            phone: 'N/A'
        },
        {
            name: {
                en: "Bangladesh Technical Education Board (BTEB)",
                bn: 'বাংলাদেশ কারিগরি শিক্ষা বোর্ড'
            },
            link: 'http://www.bteb.gov.bd/',
            email: 'N/A',
            phone: 'N/A'
        },
        {
            name: {
                en: 'Bangladesh Scounts',
                bn: 'বাংলাদেশ স্কাউটস'
            },
            link: 'http://www.scouts.gov.bd/',
            email: 'N/A',
            phone: 'N/A'
        },
    ]

    return(
        <Box sx={{ backgroundColor: '#01579b', }}>
            <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 26, fontWeight: 700, color: 'white', textAlign: 'center', padding: '25px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>{language.language === 'en' ? 'Important Weblinks' : 'গুরুত্ত্বপূর্ণ ওয়েবলিংক'}</Box>
            <Box sx={{ minHeight: '80vh', flex: 1, padding: 2 }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650, }} aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontFamily: 'Hind Siliguri', fontWeight: 'bold' }}>{language.language === 'en' ? 'Name': 'নাম'}</TableCell>
                            <TableCell align="left" sx={{ fontFamily: 'Hind Siliguri', fontWeight: 'bold' }}>{language.language === 'en' ? 'Email': 'ইমেইল'}</TableCell>
                            <TableCell align="left" sx={{ fontFamily: 'Hind Siliguri', fontWeight: 'bold' }}>{language.language === 'en' ? 'Phone': 'ফোন'}</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {links.map((row, index) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left" sx={{ fontFamily: 'Hind Siliguri' }}><Link href={row.link} underline="none">{language.language === 'en' ? row.name.en : row.name.bn}</Link></TableCell>
                                    <TableCell align="left" sx={{ fontFamily: 'Hind Siliguri' }}>{row.email}</TableCell>
                                    <TableCell align="left" sx={{ fontFamily: 'Hind Siliguri' }}>{row.phone}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    )
}
export default Weblinks;