import { useContext } from 'react';
import Box from '@mui/material/Box'; 
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { languageContext } from './context';

function History(props) {
    const host = 'https://shikkhanobish.com/';
    const [language, setLanguage] = useContext(languageContext);
      return (
      <Box sx={{ backgroundColor: '#01579b', }}>
        <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 26, fontWeight: 700, color: 'white', textAlign: 'center', padding: '25px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>{language.language == 'en' ? 'About Us' : 'আমাদের সম্পর্কে'}</Box>
              <Grid container direction="row" sx={{ padding: '30px 0', alignItems: 'center' }}>
                <Grid xs={12} md={6}>
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <Card sx={{ maxWidth: 400, padding: '5px', textAlign: 'center' }}>
                      <CardMedia sx={{ height: 320, width: 400 }} image={host + props.history.image}/>
                    </Card>
                  </Box>
                </Grid>
  
                <Grid xs={12} md={6}>
                  <Box sx={{ fontFamily: 'Hind Siliguri', textAlign: 'justify', fontSize: 16, fontWeight: 400, color: 'white', borderLeft: '2px solid yellow', padding: '30px' }}>
                    <Box sx={{ whiteSpace: 'pre-wrap', color: '#c1c1c1' }}>{language.language == 'en' ? props.history.historyEnglish : props.history.historyBengali}</Box>
                  </Box>
                </Grid>
            
              </Grid>
      </Box>
      )
  }
  
export default History;