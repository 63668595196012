import { useContext } from 'react';
import Box from '@mui/material/Box'; 
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { languageContext } from './context';
function PhotoBooth(props) {
    const host = 'https://shikkhanobish.com/';
    const [language, setLanguage] = useContext(languageContext);
      return (
        <>
            <Box sx={{ backgroundColor: '#0c454f' }}>
                <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 26, fontWeight: 700, color: 'white', textAlign: 'center', padding: '25px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>{language.language == 'en' ? 'Gallery' :'গ্যালারী'}</Box>
                <Box sx={{ padding: 4 }}>
                    <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                        <Masonry columnsCount={3} gutter="10px">
                            {
                                props.images.map((item, index) => {
                                    return(
                                        
                                            <Card sx={{ width: '100%', display: 'block' }}>
                                                <img key={item} alt={item.captionInEnglish} src={host + item.image} style={{width: "100%", display: "block"}}/>
                                                <CardContent sx={{ padding: 3 }}>
                                                    <Typography gutterBottom sx={{fontFamily: 'Hind Siliguri', margin: 0, fontSize: 20}} component="div">
                                                        {language.language == 'en' ? item.galleryCaptionInEnglish : item.galleryCaptionInBengali}
                                                    </Typography>
                                                </CardContent>
                                            </Card>
                                        
                                    )
                                })
                            }
                        </Masonry>
                    </ResponsiveMasonry>
                </Box>
            </Box>
        </>
      )
}

export default PhotoBooth;