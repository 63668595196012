import { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box'; 
import { languageContext } from './context';
import axios from 'axios';

function AdmissionGuideline(props) {
    const [language] = useContext(languageContext);
    const [admission, setAdmission] = useState(null);
    useEffect(() => {
      axios.get('https://shikkhanobish.com/api/admissionapplication')
      .then((res) => {
        setAdmission(res.data)
      })
    }, []);
      return (
      <Box sx={{ backgroundColor: '#01579b', }}>
        <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 26, fontWeight: 700, color: 'white', textAlign: 'center', padding: '25px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>{language.language === 'en' ? 'Apply for admission' : 'ভর্তি আবেদন'}</Box>
        <Box sx={{ height: '80vh', display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'center' }}>
            {
              admission != null ?
              <Box component="p" sx={{ maxWidth : 520, padding: 2, fontFamily: 'Hind Siliguri', color: 'white', fontSize: 22, whiteSpace: 'pre-wrap'}}>
                  {admission.panel.isLive === false ? admission.panel.offSpringMessage : admission.panel.guideline}
              </Box>
              :
              <></>
            }
        </Box>
      </Box>
      )
  }
  
export default AdmissionGuideline;