import * as React from 'react';
import { useContext } from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import { IconButton, Drawer, ListItemButton, ListItemText, List, Collapse, ListItemIcon, Link } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ExpandLess } from '@mui/icons-material';
import Switch from '@mui/material/Switch';
import { languageContext } from './context';
const linkhubs = [
  {
    id: 'aboutus',
    name: {
      en: 'About us',
      bn: 'আমাদের সম্পর্কে'
    },
    items: [
      {
        name: {
          en: 'Home',
          bn: 'মূলপাতা'
        },
        link: '/',
        icon : 'home'
      },
      {
        name: {
          en: 'Speeches',
          bn: 'বাণী'
        },
        link: '/speeches',
        icon : 'mic'
      },
      {
        name: {
          en : 'History',
          bn : 'ইতিহাস'
        },
        link: '/history',
        icon : 'schedule'
      },
      {
        name: {
          en: 'Notice Board',
          bn : 'নোটিশ বোর্ড'
        },
        link: '/notice',
        icon : 'notifications'
      },
      {
        name: {
          en : 'Contact Us',
          bn : 'যোগাযোগ'
        },
        link: '/contact',
        icon : 'contact_support'
      },
    ]
  },
  {
    id: 'academic',
    name: {
      en: 'Academic',
      bn: 'একাডেমিক'
    },
    items: [
      {
        name: {
          en :'Calendar',
          bn : 'ক্যালেন্ডার'
        },
        link: '/calendar',
        icon : 'calendar_month'
      },
      {
        name: {
          en : 'Syllabus',
          bn : 'পাঠ্যসূচী'
        },
        link: '/syllabus',
        icon : 'table_view'
      },
      {
        name: {
          en: 'Class Routine',
          bn: 'শ্রেণী কার্যক্রমসূচী'
        },
        link: '/routine',
        icon : 'table_chart'
      },
      {
        name: {
          en: 'Exam Routine',
          bn: 'পরীক্ষার সময়সূচী'
        },
        link: '/exam_routine',
        icon : 'table'
      },
      {
        name: {
          en : 'Important Weblinks',
          bn : 'গুরুত্ত্বপূর্ণ ওয়েবলিংক'
        },
        link: '/weblinks',
        icon : 'link'
      },
      {
        name: {
          en: 'Scholarships',
          bn: 'বৃত্তি'
        },
        link: '/scholarships',
        icon : 'school'
      }
    ]
  },
  {
    id: 'admission',
    name: {
      en: 'Admission',
      bn: 'ভর্তি'
    },
    items: [
      {
        name: {
          en: 'Apply for admission',
          bn: 'ভর্তির আবেদন'
        },
        link: '/admission_apply',
        icon : 'drive_file_rename_outline'
      },
      {
        name: {
          en: 'Check admission status',
          bn: 'ভর্তির আবেদনের অবস্থা যাচাই'
        },
        link: '/admission_check',
        icon : 'fact_check'
      },
      {
        name: {
          en: 'Admission guideline',
          bn: 'ভর্তি আবেদনের নিয়মাবলি'
        },
        link: '/admission_guideline',
        icon : 'text_snippet'
      },
      {
        name: {
          en: 'Teletalk School Admission',
          bn: 'টেলিটক স্কুল ভর্তি আবেদন'
        },
        link: 'https://gsa.teletalk.com.bd/',
        icon : 'text_fields'
      }
    ]
  },
  {
    id: 'teachers',
    name: {
      en: 'Teachers',
      bn: 'শিক্ষকমণ্ডলী'
    },
    items: [
      {
        name: {
          en: 'Current Teachers',
          bn: 'বর্তমান শিক্ষকমণ্ডলী'
        },
        link: '/teachers',
        icon : 'emoji_people'
      },
      {
        name: {
          en: 'Current Staffs',
          bn: 'বর্তমান কর্মকর্তাগণ'
        },
        link: '/staffs',
        icon : 'hail'
      },
      {
        name: {
          en: 'Non Academic people',
          bn: 'অপ্রাতিষ্ঠানিক ব্যক্তিবর্গ'
        },
        link: '/nap',
        icon : 'engineering'
      }
    ]
  },
  {
    id: 'result',
    name: {
      en: 'Results',
      bn: 'ফলাফল'
    },
    items: [
      {
        name: {
          en: 'Academic results',
          bn: 'প্রাতিষ্ঠানিক ফলাফল'
        },
        link: 'https://student.shikkhanobish.com',
        icon : 'receipt_long'
      },
      {
        name: {
          en: 'Board Results',
          bn: 'বোর্ড ফলাফল'
        },
        link: 'http://www.educationboardresults.gov.bd/',
        icon : 'post_add'
      },
    ]
  },
  {
    id: 'activities',
    name: {
      en: 'Activities',
      bn: 'কার্যক্রম'
    },
    items: [
      {
        name: {
          en: 'Events',
          bn: 'কর্মসূচী'
        },
        link: '/events',
        icon : 'celebration'
      },
      {
        name: {
          en: 'Clubs',
          bn: 'সংঘসমূহ'
        },
        link: '/clubs',
        icon : 'hub'
      },
      {
        name: {
          en: 'Arts and Culture',
          bn: 'সংস্কৃতি ও কলা'
        },
        link: '/arts',
        icon : 'palette'
      },
      {
        name: {
          en: 'Gallery',
          bn: 'গ্যালারী'
        },
        link: '/gallery',
        icon : 'imagesmode'
      },
    ]
  },
  {
    id: 'logins',
    name: {
      en: 'Login',
      bn: 'লগ-ইন'
    },
    items: [
      {
        name: {
          en: 'Student Login',
          bn: 'শিক্ষার্থী লগ-ইন'
        },
        link: 'https://student.shikkhanobish.com',
        icon : 'account_circle_outlined'
      },
      {
        name: {
          en: 'Admin Login',
          bn: 'এডমিন লগ-ইন'
        },
        link: 'https://shikkhanobish.com',
        icon : 'manage_accounts_outlined'
      },
    ]
  }
]

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function SiteMaps() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(null);
  const [desktopAnchorEl, setDesktopAnchorEl] = React.useState(null);
  const [desktopOpen, setDesktopOpen] = React.useState(null);
  const [languageEn, setLanguageEn] = React.useState(false);
  const [drawer, setDrawer] = React.useState(false);
  const [language, setLanguage] = useContext(languageContext);
  const handleClick = (e, i) => {
    setAnchorEl(e.currentTarget);
    setOpen(i)
  };
  const mouseClick = (e, i) => {
    setDesktopAnchorEl(e.currentTarget);
    setDesktopOpen(i)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(null)
  };
  const changeLanguage = () => {
    setLanguageEn(!languageEn);
    setLanguage(language.language === 'en' ? {language: 'bn'} : {language: 'en'})
  };
    return(
    <>
    <Box sx={{ background: '#01579b', padding: '10px', display: 'flex' }}>
      <IconButton color="white" aria-label="menu" onClick={() => setDrawer(!drawer)} sx={{ display: { md: 'none', lg: 'none', xl: 'none' } }}>
        <MenuIcon sx={{ color: 'white'}} />
      </IconButton>
      <Button id="home-button" variant="contained" disableElevation disableRipple size="small" sx={{ borderRadius: '30px', paddingX: '20px', marginX: '10px', textTransform: 'capitalize', background: '#0d4775', display: { xs: 'none', sm: 'none' } }}>
          Home
      </Button>
      {
        linkhubs.map((item, i) =>{
          return (
            <>
              <Button id={item.id + '-button'} aria-controls={open === i ? item.id + '-menu' : undefined} aria-haspopup="true" aria-expanded={open === i ? 'true' : undefined} variant="contained" disableElevation onClick={(event) => handleClick(event, i)} disableRipple size="small" sx={{ fontFamily: 'Hind Siliguri', borderRadius: '30px', paddingX: '20px', marginX: '10px', textTransform: 'capitalize', background: '#0d4775', display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex', xl: 'flex' } }}>
                  {language.language === 'en' ? item.name.en : item.name.bn}
              </Button>
              <StyledMenu id={item.id + '-menu'} MenuListProps={{ 'aria-labelledby': item.id + '-button'}} anchorEl={anchorEl} open={open === i} onClose={handleClose}>
                {
                  item.items.map((links, j) => {
                    return(
                      <Link href={links.link} underline="none" sx={{ color: 'black' }}>
                        <MenuItem onClick={handleClose} disableRipple sx={{ fontFamily: 'Hind Siliguri', }}>
                          <Icon sx={{ marginRight: 2, color: 'black' }}>{links.icon}</Icon>
                            {language.language === 'en' ? links.name.en : links.name.bn}
                        </MenuItem>
                      </Link>
                    )
                  })
                }
              </StyledMenu>
            </>
          )
        })
      }
      <Box sx={{ flex: 1 }}></Box>
      <Switch checked={languageEn} onChange={changeLanguage} color='warning' inputProps={{ 'aria-label': 'controlled' }} />
    </Box>
    <Drawer open={drawer} onClose={() => setDrawer(false)} PaperProps={{ sx: { backgroundColor: "#01579b", }}}>
      <Box sx={{ width: 300, background: '#01579b' }}>
      <List omponent="nav" dense>

      {
         linkhubs.map((item, index) =>  {return(
          <>
            <ListItemButton onClick={(event) => mouseClick(event, index)} sx={{ background: '#01579b' }}>
              <ListItemText primary={language.language === 'en' ? item.name.en : item.name.bn} sx={{ color: 'white' }}/>
              {desktopOpen ? <ExpandLess sx={{ color: 'white' }}/> : <ExpandMore sx={{ color: 'white' }}/>}
            </ListItemButton>
            
                {
                  item.items.map((item, i) =>  {
                    return(
                      <Collapse in={desktopOpen === index} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding dense sx={{ background: '#084778', color: 'white' }}>
                              <ListItemButton sx={{ pl: 4 }} href={item.link}>
                                <ListItemIcon>
                                  <Icon sx={{ marginRight: 2, color: 'white' }}>{item.icon}</Icon>
                                </ListItemIcon>
                                <ListItemText primary={language.language === 'en' ? item.name.en : item.name.bn} sx={{ fontFamily: 'Hind Siliguri', }} />
                              </ListItemButton>

                          </List>
                      </Collapse>
                  )})
                }
          </>
         );
         })
        }
    </List>

      </Box>
    </Drawer>
    </>
    )
}

export default SiteMaps;