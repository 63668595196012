import { useContext } from 'react';
import Box from '@mui/material/Box'; 
import Lottie from "lottie-react";
import animation from '../assets/email-marketing.json';
import Grid from '@mui/material/Grid';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { useState } from "react";
import moment from 'moment';
import { languageContext } from './context';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: props.id === props.expanded ? 'white': '' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function Notice(props) {
    const host = 'https://shikkhanobish.com/';
    const [language, setLanguage] = useContext(languageContext);
    const [expanded, setExpanded] = useState('panel0');
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
      return(
        <Box sx={{ backgroundColor: '#0c454f' }} id="notifications">
            <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 26, fontWeight: 700, background: '#083a42', color: 'white', textAlign: 'center', padding: '25px' }}>{ language.language == 'en' ? 'Notice Board' : 'নোটিশ বোর্ড'}</Box>
            <Grid container>
                <Grid xs={12} md={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ maxWidth: 450, display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            <Lottie animationData={animation} loop={true} />
                        </Box>
                    </Box>
                </Grid>
                <Grid xs={12} md={6}>
                    <Box sx={(theme) => ({ display: 'flex', flexDirection: 'column', height: 500, overflow: 'hidden auto', '&::-webkit-scrollbar': { width: 5, WebkitAppearance: 'none' }, '&::-webkit-scrollbar-thumb': { borderRadius: 8, backgroundColor: 'rgba(0 0 0 / 0.5)', }, margin: '20px'})}>
                        {
                            props.notices.map((notice, i) => {
                                return(
                                    <Accordion expanded={expanded === 'panel'+i} onChange={handleChange('panel'+i)} key={i}>
                                        <AccordionSummary aria-controls="panel1d-content" id={'panel'+i} expanded={expanded} sx={{ backgroundColor: expanded === 'panel' + i ? '#01579b': '', '&:hover': {backgroundColor: '#01579b', color: 'white' }}}>
                                            <Typography sx={{ fontFamily: 'Hind Siliguri', fontSize: 16, fontWeight: 500, color: expanded === 'panel' + i ? 'white' : '' }}>{notice.title + ' (' + moment(notice.time).format('DD MMM[\n]YYYY') + ')'}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography sx={{ fontFamily: 'Hind Siliguri', fontSize: 16, fontWeight: 400}}>{notice.body}</Typography>
                                            {
                                                notice.attachment !== '' ? <Chip label={language.language == 'en' ? 'Attachment' : 'সংযুক্তি'} variant="outlined" sx={{ marginY: '2px', borderRadius: '4px', fontStyle: 'italic' }} component="a" href={host + notice.attachment} clickable /> : <></>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            })
                        }
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Notice;