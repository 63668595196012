import './App.css';
import Speech from './views/speech';
import Notice from './views/notice';
import History from './views/history';
import Carousel from './views/carousel';
import SiteTopBar from './views/sitetopbar';
import AnimatedNotice from './views/animatedNotice';
import PhotoBooth from './views/gallery';
import SiteMaps from './views/sitemaps';
import Footer from './views/footer';
import Calendar from './views/calendar';
import Weblinks from './views/weblinks';
import Teachers from './views/teachers';
import AdmissionApply from './views/admissionApply';
import AdmissionGuideline from './views/admissionGuideline';
import { languageContext } from './views/context';
import {CircularProgress, Box} from '@mui/material';
import { useState, useEffect } from "react"; 
import {filter} from "lodash";
import axios from 'axios';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ApplicationFormDownload from './views/applicationFormDownload';
function App() {
  const [instituteInfo, setInstituteInfo] = useState(null);
  const [eiin, setEIIN] = useState(null);
  const [teachersData, setTeachersData] = useState(null);
  const [language, setLanguage] = useState({language: 'bn'});
  const host = 'https://shikkhanobish.com/'
  useEffect(() => {
    const hostname = window.location.hostname === 'localhost' ? 'fmhs.edu.bd' : window.location.hostname;
    axios.get('https://shikkhanobish.com/api/website?url='+hostname)
    .then((res) =>{
      setInstituteInfo(res.data.panel);
      setEIIN(res.data.eiin);
      setTeachersData(res.data.teachers);
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = host + res.data.panel.logo;
      document.title = res.data.panel.basicInfo.en.instituteName + ' || ' + res.data.panel.basicInfo.bn.instituteName
    })
  }, [])

  if(instituteInfo == null) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    )
  } else {
    return (
      <BrowserRouter>
      <Routes>
        <Route index element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
                <SiteMaps />
                <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
                <AnimatedNotice notices={instituteInfo.notices} />
                <Carousel slides={instituteInfo.slides} />
                <Speech speeches={instituteInfo.speeches} />
                <Notice notices={instituteInfo.notices}/>
                <History history={instituteInfo.history} />
                <PhotoBooth images={instituteInfo.gallery} />
                <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>

        <Route path="/speeches" element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
              <SiteMaps />
              <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
              <Speech speeches={instituteInfo.speeches} />
              <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>

        <Route path="/history" element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
                <SiteMaps />
                <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
                <History history={instituteInfo.history} />
                <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>

        <Route path="/notice" element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
                <SiteMaps />
                <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
                <Notice notices={instituteInfo.notices}/>
                <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>
        <Route path="/gallery" element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
                <SiteMaps />
                <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
                <PhotoBooth images={instituteInfo.gallery} />
                <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>
        <Route path="/calendar" element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
                <SiteMaps />
                <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
                <Calendar calendar={instituteInfo.calendar} />
                <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>
        <Route path="/weblinks" element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
                <SiteMaps />
                <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
                <Weblinks />
                <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>
        <Route path="/teachers" element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
                <SiteMaps />
                <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
                <Teachers teachers={ filter(teachersData, ['sector', 'Teacher'])} sector={{en: 'Current Teachers', bn: 'বর্তমান শিক্ষকমণ্ডলী'}}/>
                <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>
        <Route path="/staffs" element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
                <SiteMaps />
                <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
                <Teachers teachers={ filter(teachersData, ['sector', 'Staff'])} sector={{en: 'Current Staffs', bn: 'বর্তমান কর্মকর্তাগণ'}}/>
                <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>
        <Route path="/nap" element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
                <SiteMaps />
                <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
                <Teachers teachers={ filter(teachersData, ['sector', 'Others'])} sector={{en: 'Non Academic People', bn: 'অপ্রাতিষ্ঠানিক ব্যক্তিবর্গ'}}/>
                <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>
        <Route path="/admission_apply" element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
                <SiteMaps />
                <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
                <AdmissionApply eiin={eiin} />
                <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>
        <Route path="/admission_guideline" element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
                <SiteMaps />
                <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
                <AdmissionGuideline  basicinfo={instituteInfo.basicInfo} />
                <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>
        <Route path="/admission_check" element={
          <languageContext.Provider value={[language, setLanguage]}>
            <Box sx={{ background: '#0b1120' }}>
              <Box sx={{ maxWidth: '1600px', background: '#ffffff', marginX: 'auto' }}>
                <SiteMaps />
                <SiteTopBar basicinfo={instituteInfo.basicInfo} logo={instituteInfo.logo} />
                <ApplicationFormDownload eiin={eiin} />
                <Footer basicinfo={instituteInfo.basicInfo} />
              </Box>
            </Box>
          </languageContext.Provider>
        }></Route>
      </Routes>
    </BrowserRouter>
      
      
      
    );
  }
}

export default App;
