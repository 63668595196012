import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Marquee from "react-fast-marquee";


function AnimatedNotice(props) {

    return(
        <>
            <Box sx={{ padding: '10px', background: '#01579b', overflowX: 'hidden' }}>
                <Marquee direction="left" speed="30" id="marquee_notice" gradient={false} pauseOnHover>
                    <Box sx={{ width: 'max-content'}}>
                    {
                        props.notices.map((notice, i) => {
                            return (
                                <Chip label={notice.title} key={i} sx={{ marginX: '5px', color: 'white', fontFamily: 'Hind Siliguri', fontSize: 14, fontWeight: 400, background: '#0d4775' }} component="a" href="#notifications"></Chip>
                            );
                        })
                    }
                    </Box>
                </Marquee>
            </Box>
        </>
    );
}

export default AnimatedNotice;