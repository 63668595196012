import { useContext } from 'react';
import { Box, Typography, Card, CardMedia } from '@mui/material';
import { languageContext } from './context';
function Calendar(props) {
    const host = 'https://shikkhanobish.com/';
    const [language, setLanguage] = useContext(languageContext);
    return(
        <>
            <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 26, fontWeight: 700, color: 'black', textAlign: 'center', padding: '10px 25px 25px 25px' }}>{language.language == 'en' ? 'Academic Calendar' : 'একাডেমিক ক্যালেন্ডার'}</Box>
            <Card sx={{ width: '100%', display: 'block' }}>
                <img alt="Academic calendar" src={host + props.calendar.attachment} style={{width: "100%", display: "block"}}/>
            </Card>
        </>
    )
}
export default Calendar;