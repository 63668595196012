import { useContext } from 'react';
import Box from '@mui/material/Box'; 
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { languageContext } from './context';
function Teachers(props) {
    const host = 'https://shikkhanobish.com/';
    const [language] = useContext(languageContext);
      return (
        <>
            <Box sx={{ backgroundColor: '#0c454f' }}>
                <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 26, fontWeight: 700, color: 'white', textAlign: 'center', padding: '25px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>{language.language === 'en' ? props.sector.en : props.sector.bn}</Box>
                {
                    props.teachers.length !== 0 ? 
                            <Box sx={{ padding: 4 }}>
                            <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}>
                                <Masonry columnsCount={3} gutter="10px">
                                    {
                                        props.teachers.map((item, index) => {
                                            return(
                                                <Card sx={{ width: '100%', display: 'block' }}>
                                                    <img key={item} alt={item.firstName + ' ' + item.lastName} src={host + 'uploads/' + item.avatar} style={{width: "100%", display: "block"}}/>
                                                    <CardContent sx={{ padding: 3 }}>
                                                        <Typography gutterBottom sx={{fontFamily: 'Hind Siliguri', margin: 0, fontSize: 20}} component="div">
                                                            {item.firstName + ' ' + item.lastName}
                                                        </Typography>
                                                        <Typography gutterBottom sx={{fontFamily: 'Hind Siliguri', margin: 0, fontSize: 16}} component="div">
                                                            {item.designation}
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            )
                                        })
                                    }
                                </Masonry>
                            </ResponsiveMasonry>
                        </Box>
                    :
                    <Box sx={{ height: '80vh', display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'center' }}>
                        <Typography gutterBottom sx={{fontFamily: 'Hind Siliguri', margin: 0, fontSize: 22, color: 'white', fontStyle: 'italic'}} component="div">
                           {  language.language === 'en' ? props.sector.en + ' have not registered yet.' : 'তালিকায় ' +  props.sector.bn + ' লিপিবদ্ধ হয়নি।'}
                        </Typography>
                    </Box>
                }
            </Box>
        </>
      )
}

export default Teachers;