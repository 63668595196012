import Box from '@mui/material/Box'; 
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { Link } from '@mui/material';
import { useContext } from 'react';
import { languageContext } from './context';
function Footer(props) {
    const [language, setLanguage] = useContext(languageContext);
    return(
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 2, background: '#0b1120', fontFamily: 'Hind Siliguri', color: 'grey' }}>
                <Box>
                    {language.language == 'en' ? 'Copyright © ' + props.basicinfo.en.instituteName + '. All rights reserved.' : 'কপিরাইট © ' + props.basicinfo.bn.instituteName + '। সর্বস্বত্ব সংরক্ষিত।'}
                </Box>
                <Box>
                    <Link href="//shikkhanobish.com" underline="none">
                        {language.language == 'en' ? 'Technical support by Shikkhanobish' : 'টেকনিক্যাল সহায়তায় শিক্ষানবিশ'}
                    </Link>
                </Box>
            </Box>
        </>
    )
}

export default Footer;