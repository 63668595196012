import { useContext } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { languageContext } from './context';

function Speech(props) {
  const host = 'https://shikkhanobish.com/';
  const [language, setLanguage] = useContext(languageContext);
    return (
    <Box sx={{ backgroundColor: '#01579b', padding: '25px 0 0 0' }}>
      <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 26, fontWeight: 700, color: 'white', textAlign: 'center', padding: '10px 25px 25px 25px' }}>{language.language === 'en' ? 'Speeches' : 'বিশিষ্টগণের বাণী'}</Box>
      {
        props.speeches.map((speech, i) =>{
          return(
            <Grid container direction={ i % 2 === 0 ? 'row': 'row-reverse'} sx={{ padding: '30px 0', alignItems: 'center', backgroundColor: i % 2 === 0 ? 'rgba(0, 0, 0, 0.2)': '' }} key={i}>
              
              <Grid xs={12} md={6}>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                  <Card sx={{ maxWidth: 260, padding: '5px', textAlign: 'center' }}>
                    <CardMedia sx={{ height: 260, width: 260 }} image={host + speech.speakerImage}/>
                  </Card>
                </Box>
              </Grid>

              <Grid xs={12} md={6}>
                <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 16, fontWeight: 400, color: 'white', borderLeft: i % 2 === 0 ? '2px solid yellow': '', borderRight: i % 2 !== 0 ? '2px solid yellow': '', padding: '30px' }}>
                  <Box sx={{ whiteSpace: 'pre-wrap', color: '#c1c1c1', textAlign: 'justify' }}>{language.language === 'en' ? speech.speechBodyInEnglish : speech.speechBodyInBengali}</Box>
                  <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 16, fontWeight: 700, color: 'white', marginTop: '15px' }}>{language.language === 'en' ? speech.speakerFullNameinEnglish : speech.speakerFullNameInBengali}</Box>
                  <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 16, fontWeight: 500, color: 'white', }}>{language.language === 'en' ? speech.speakerDesignationInEnglish : speech.speakerDesignationInBenglai}</Box>
                </Box>
              </Grid>
          
            </Grid>
          )
        })
      }
      
    </Box>
    )
}

export default Speech;