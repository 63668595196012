import axios from 'axios';
import { Box, Button, Card, TextField } from '@mui/material';
import { useContext } from 'react';
import { languageContext } from './context';
import InputMask from "react-input-mask";
import { useState } from 'react';
import { useEffect } from 'react';

export default function ApplicationFormDownload(props) {
    const [language] = useContext(languageContext);
    const [birthCertificateNo, setbirthCertificateNo] = useState('');
    const [eiin, setEiin] = useState(props.eiin)
    const checkDownloadFile = async () => {
        axios.get("https://shikkhanobish.com/api/downloadadmissionform?eiin=" + eiin + "&birthcertificateno=" + birthCertificateNo)
        .then((res) => {
            if(res.data.error === false) {
                alert(res.data.message);
            } else {
                window.location.href= "https://shikkhanobish.com/api/downloadadmissionform?eiin=" +  eiin + "&birthcertificateno=" + birthCertificateNo
            }
        })
    }

    return(
        <Box sx={{ backgroundColor: '#01579b', }}>
            <Box sx={{ fontFamily: 'Hind Siliguri', fontSize: 26, fontWeight: 700, color: 'white', textAlign: 'center', padding: '25px', backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>{language.language === 'en' ? 'Admission Application Download' : 'ভর্তি আবেদনপত্র ডাউনলোড'}</Box>
            <Card sx={{ background: 'white', padding: 4, margin: 4 }}>
                <Box sx={{  display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'center' }}>
                    <InputMask mask="99999999999999999999" maskChar={null} alwaysShowMask={false} value={birthCertificateNo} onChange={(e) => setbirthCertificateNo(e.target.value)}>
                        {() =><TextField label="Applicant Birth Certificate no" id="applicant-first-name" size="small" sx={{ flex: 1, margin: 1 }} />}
                    </InputMask>
                    <Button color="success" variant="contained" onClick={() => {checkDownloadFile()}}>Download</Button>
                </Box>
            </Card>
            <Box sx={{ minHeight: '50vh' }}></Box>
        </Box>
    )
}